import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import styled from "styled-components";
import { rem, space } from "../design-system";
import Tag from "../components/Tag";

const Header = styled.header`
  margin-bottom: ${rem(space(600))};
  font-family: system-ui;
`;
const Title = styled.h1`
  margin-bottom: ${rem(space(200))};
`;
const Image = styled.img`
  max-width: 100%;
  margin-bottom: ${rem(space(400))};

  @media screen and (min-width: 600px) {
    max-width: 50%;
    float: right;
    margin-left: ${rem(space(400))};
  }
`;
const Content = styled.div`
  & p {
    line-height: 1.5;
  }
`;

export default ({ data }) => {
  const { title, date, image, rent } = data.property.frontmatter;
  const { html } = data.property;

  return (
    <Layout>
      <Header>
        <Title>{title}</Title>
        <small>
          Listed on <strong>{date}</strong>
        </small>
        &nbsp;
        {!!rent && <Tag>${rent}</Tag>}
        <Link to={`/${data.propertyType.childMarkdownRemark.fields.slug}`}>
          <Tag>{data.propertyType.childMarkdownRemark.frontmatter.name}</Tag>
        </Link>
      </Header>
      <Image src={image} />
      <Content dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  );
};

export const query = graphql`
  query($slug: String!, $propertyType: String!) {
    propertyType: file(
      sourceInstanceName: { eq: "propertyTypes" }
      childMarkdownRemark: { frontmatter: { name: { eq: $propertyType } } }
    ) {
      childMarkdownRemark {
        fields {
          slug
        }
        frontmatter {
          name
        }
      }
    }
    property: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        type
        image
        date(formatString: "MMMM D, YYYY")
        type
        rent
      }
    }
  }
`;
